import { Box, Grid, InputAdornment, Paper, TextField, Typography } from "@mui/material";
import Button from "../../common/Button/Button";
import { Button as Btn } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import EventOutlinedIcon from "@mui/icons-material/EventOutlined";
import { TimePicker } from "@mui/x-date-pickers";
import ScheduleOutlinedIcon from "@mui/icons-material/ScheduleOutlined";
const Step2DeskTop = (props) => {
  const { nameChange, name, phoneNumberChange, phoneNumber, emailChange, email, selectedDate, handleDateChange, selectedTime, handleTimeChange, handleStep2Click, error, handleBackClick, step, isPhoneNumberValid } = props;
  return (
    <Paper sx={{ pt: 4, pb: 4, pl: 6, pr: 6, borderRadius: 10, mt: -15, zIndex: 0, position: "relative" }}>
      <Box>
        {step > 1 && (
          <Btn size="small" color="primary" onClick={handleBackClick}>
            <KeyboardBackspaceIcon /> Back
          </Btn>
        )}
      </Box>
      <Typography align="left" variant="body1" sx={{ mb: 3 }}>
        Please fill out the form below
      </Typography>

      <Box sx={{ mt: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <TextField size="small" fullWidth label="Name" variant="outlined" onChange={nameChange} value={name} />
          </Grid>
          <Grid item xs={4}>
            <TextField
              type="number"
              size="small"
              fullWidth
              label="Phone Number"
              variant="outlined"
              InputProps={{
                startAdornment: <InputAdornment position="start">04</InputAdornment>,
              }}
              onChange={phoneNumberChange}
              value={phoneNumber}
            />
          {!isPhoneNumberValid && <Typography color='error' variant="caption">Phone number is not valid</Typography> }
          </Grid>
          <Grid item xs={4}>
            <TextField size="small" fullWidth label="Email" variant="outlined" onChange={emailChange} value={email} />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                disablePast
                sx={{ mt: 3, width: "100%" }}
                value={selectedDate}
                onChange={handleDateChange}
                slotProps={{
                  textField: {
                    InputProps: {
                      startAdornment: (
                        <InputAdornment position="start">
                          <EventOutlinedIcon />
                        </InputAdornment>
                      ),
                    },
                    size: "small",
                    label: "Booking Date",
                  },
                }}
              ></DatePicker>
            </LocalizationProvider>
          </Grid>
          <Grid item xs={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker
                disablePast
                sx={{ mt: 3, width: "100%" }}
                value={selectedTime}
                onChange={handleTimeChange}
                slotProps={{
                  textField: {
                    InputProps: {
                      startAdornment: (
                        <InputAdornment position="start">
                          <ScheduleOutlinedIcon />
                        </InputAdornment>
                      ),
                      placeholder:"hh:mm:ss"
                    },
                    size: "small",
                    label: "Booking Time",
                  },
                }}
              ></TimePicker>
            </LocalizationProvider>
          </Grid>
        </Grid>

        <Typography variant="body2" color="error" sx={{ m: 1 }} align="center">
          {error && error.message}
        </Typography>
        <Box sx={{ mt: 3 }}>
          <Button name="Next" fullWidth={true} onClick={handleStep2Click} loading={false} />
        </Box>
      </Box>
    </Paper>
  );
};

export default Step2DeskTop;
