import { Box, Divider, Grid, Paper, Typography } from "@mui/material";
import Button from "../../common/Button/Button";
import { Button as Btn } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useState } from "react";
import { getFare } from "../../../services/BookingService";
import { useEffect } from "react";
const Step4DeskTop = (props) => {
  const [fare, setFare] = useState(0.0);
  const { reviewData, handleConfirmOrderClick, handleBackClick, step, btnLoading } = props;
  const options = { hour12: true };

  useEffect(() => {
    getTotalFare(reviewData[9].value);
  }, [reviewData]);

  const getTotalFare = (distance) => {
    getFare({ distance })
      .then((response) => {
        setFare(response.data.data.fare);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <Paper sx={{ pt: 4, pb: 4, pl: 6, pr: 6, borderRadius: 10, mt: -15, zIndex: 0, position: "relative" }}>
      <Box>
        {step > 1 && (
          <Btn size="small" color="primary" onClick={handleBackClick}>
            <KeyboardBackspaceIcon /> Back
          </Btn>
        )}
      </Box>
      <Typography align="left" variant="body1" sx={{ mb: 3 }}>
        Please review your booking details before proceeding:
      </Typography>

      <Box sx={{ mt: 3 }}>
        {/* <Grid container spacing={2}>
          <Grid item xs={3}></Grid>
          <Grid item xs={6}>
            <Box sx={{ border: "solid 1px" }}>
              <Grid container spacing={2}>
                <Grid item xs={6} sx={{textAlign:'center'}}>
                  <Typography sx={{p:1}} variant="body2" color="textSecondary">
                    Pick Up
                  </Typography>
                </Grid>
                <Grid item xs={6} sx={{textAlign:'center'}}>
                <Typography sx={{p:1}} variant="body2" color="textSecondary">
                    <strong>{reviewData[0]}</strong>
                  </Typography>
                </Grid>
              </Grid>
              <Divider />
              <Grid container spacing={2}>
                <Grid item xs={6} sx={{textAlign:'center'}}>
                  <Typography sx={{p:1}} variant="body2" color="textSecondary">
                    Drop Off
                  </Typography>
                </Grid>
                <Grid item xs={6} sx={{textAlign:'center'}}>
                <Typography sx={{p:1}} variant="body2" color="textSecondary">
                    <strong>{reviewData[1]}</strong>
                  </Typography>
                </Grid>
              </Grid>
              <Divider />
              <Grid container spacing={2}>
                <Grid item xs={6} sx={{textAlign:'center'}}>
                  <Typography sx={{p:1}} variant="body2" color="textSecondary">
                  Name
                  </Typography>
                </Grid>
                <Grid item xs={6} sx={{textAlign:'center'}}>
                <Typography sx={{p:1}} variant="body2" color="textSecondary">
                    <strong>{reviewData[2]}</strong>
                  </Typography>
                </Grid>
              </Grid>
              <Divider />
              <Grid container spacing={2}>
                <Grid item xs={6} sx={{textAlign:'center'}}>
                  <Typography sx={{p:1}} variant="body2" color="textSecondary">
                  Phone Number
                  </Typography>
                </Grid>
                <Grid item xs={6} sx={{textAlign:'center'}}>
                <Typography sx={{p:1}} variant="body2" color="textSecondary">
                    <strong>{reviewData[3]}</strong>
                  </Typography>
                </Grid>
              </Grid>
              <Divider />
              <Grid container spacing={2}>
                <Grid item xs={6} sx={{textAlign:'center'}}>
                  <Typography sx={{p:1}} variant="body2" color="textSecondary">
                    Email
                  </Typography>
                </Grid>
                <Grid item xs={6} sx={{textAlign:'center'}}>
                <Typography sx={{p:1}} variant="body2" color="textSecondary">
                    <strong>{reviewData[4]}</strong>
                  </Typography>
                </Grid>
              </Grid>
              <Divider />
              <Grid container spacing={2}>
                <Grid item xs={6} sx={{textAlign:'center'}}>
                  <Typography sx={{p:1}} variant="body2" color="textSecondary">
                    Bookin Dtae
                  </Typography>
                </Grid>
                <Grid item xs={6} sx={{textAlign:'center'}}>
                <Typography sx={{p:1}} variant="body2" color="textSecondary">
                <strong>{reviewData[5].$d.toLocaleDateString()}</strong>
                  </Typography>
                </Grid>
              </Grid>
              <Divider />
              <Grid container spacing={2}>
                <Grid item xs={6} sx={{textAlign:'center'}}>
                  <Typography sx={{p:1}} variant="body2" color="textSecondary">
                    Booking Time
                  </Typography>
                </Grid>
                <Grid item xs={6} sx={{textAlign:'center'}}>
                <Typography sx={{p:1}} variant="body2" color="textSecondary">
                    <strong>{reviewData[6].$d.toLocaleTimeString(undefined, options)}</strong>
                  </Typography>
                </Grid>
              </Grid>
              <Divider />
              <Grid container spacing={2}>
                <Grid item xs={6} sx={{textAlign:'center'}}>
                  <Typography sx={{p:1}} variant="body2" color="textSecondary">
                    Flight Number
                  </Typography>
                </Grid>
                <Grid item xs={6} sx={{textAlign:'center'}}>
                <Typography sx={{p:1}} variant="body2" color="textSecondary">
                    <strong>{reviewData[7]}</strong>
                  </Typography>
                </Grid>
              </Grid>
              <Divider />
              <Grid container spacing={2}>
                <Grid item xs={6} sx={{textAlign:'center'}}>
                  <Typography sx={{p:1}} variant="body2" color="textSecondary">
                    Passengers
                  </Typography>
                </Grid>
                <Grid item xs={6} sx={{textAlign:'center'}}>
                <Typography sx={{p:1}} variant="body2" color="textSecondary">
                    <strong>{reviewData[8]}</strong>
                  </Typography>
                </Grid>
              </Grid>
              <Divider />
            </Box>
          </Grid>
          <Grid item xs={3}></Grid>
        </Grid> */}

        <Grid container spacing={2}>
          <Grid item xs={2}></Grid>
          <Grid item xs={4}>
            <Typography sx={{ mb: 1 }} variant="body2" color="textSecondary">
              Name: <strong>{reviewData[2]}</strong>
            </Typography>
            <Typography sx={{ mb: 1 }} variant="body2" color="textSecondary">
              Phone Number: <strong>04{reviewData[3]}</strong>
            </Typography>
            <Typography sx={{ mb: 1 }} variant="body2" color="textSecondary">
              Email: <strong>{reviewData[4]}</strong>
            </Typography>
            <Typography sx={{ mb: 1 }} variant="body2" color="textSecondary">
              Pick Up: <strong>{reviewData[0]}</strong>
            </Typography>
            <Typography sx={{ mb: 1 }} variant="body2" color="textSecondary">
              Drop Off: <strong>{reviewData[1]}</strong>
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography sx={{ mb: 1 }} variant="body2" color="textSecondary">
              No. of Passengers: <strong>{reviewData[8]}</strong>
            </Typography>
            <Typography sx={{ mb: 1 }} variant="body2" color="textSecondary">
              Date: <strong>{reviewData[5].$d.toLocaleDateString()}</strong>
            </Typography>
            <Typography sx={{ mb: 1 }} variant="body2" color="textSecondary">
              Time: <strong>{reviewData[6].$d.toLocaleTimeString(undefined, options)}</strong>
            </Typography>
            <Typography sx={{ mb: 1 }} variant="body2" color="textSecondary">
              Flight Number: <strong>{reviewData[7]}</strong>
            </Typography>
            <Typography sx={{ mb: 1 }} variant="body2" color="textSecondary">
              Distance: <strong>{reviewData[9] && reviewData[9].text}</strong>
            </Typography>
          </Grid>
          <Grid item xs={2} textAlign="center">
            <Divider />
            <Box >
            <Typography sx={{ fontWeight: "bold" }} variant="body2" color={"GrayText"}>
              Your fare is
            </Typography>
            <Typography sx={{ fontWeight: "bold" }} variant="h5" color={"GrayText"}>
              ${fare}
            </Typography>
            </Box>
            <Divider />
          </Grid>
        </Grid>
        <Box sx={{ mt: 3 }}>
          <Button name="Confirm Booking" fullWidth={true} onClick={handleConfirmOrderClick} loading={btnLoading} />
        </Box>
      </Box>
    </Paper>
  );
};

export default Step4DeskTop;
