import React, { useEffect, useState } from "react";
import { appName, containerMaxWith } from "../utils/helpers";

import Cover from "../components/user/Cover/Cover";
import { Box, Container } from "@mui/system";
import { Typography, Button as Btn, Grid } from "@mui/material";
import bottomImage from "../assets/img/image1.png";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useMediaQuery, useTheme } from "@mui/material";
import Step1Mobile from "../components/user/Step1Mobile/Step1Mobile";
import Step1DeskTop from "../components/user/Step1DeskTop/Step1DeskTop";
import Step2Mobile from "../components/user/Step2Mobile/Step2Mobile";
import Step2DeskTop from "../components/user/Step2DeskTop/Step2DeskTop";
import Step3Mobile from "../components/user/Step3Mobile/Step3Mobile";
import Step3DeskTop from "../components/user/Step3DeskTop/Step3DeskTop";
import Step4Mobile from "../components/user/Step4Mobile/Step4Mobile";
import Step4DeskTop from "../components/user/Step4DeskTop/Step4DeskTop";
import PhoneNumber from "libphonenumber-js";

import { completed, confirmBooking } from "../services/BookingService";
import { useSearchParams } from "react-router-dom";
import AlertDialog from "../components/common/Dialog/Dialog";
import WhatsAppBtn from "../components/common/WhatsAppBtn/WhatsAppBtn";

const isValidAustralianNumber = (phoneNumber) => {
  try {
    let pn = "04" + phoneNumber;
    const parsedNumber = PhoneNumber(pn, "AU");
    if (parsedNumber) {
      return parsedNumber.isValid();
    }
  } catch (error) {
    return false;
  }
};
const Home = () => {
  const [step, setStep] = useState(1);
  const [pickUp, setPickUp] = useState("");
  const [dropOff, setDropOff] = useState("");
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState(undefined);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [flightNumber, setFlightNumber] = useState("");
  const [passengers, setPassengers] = useState(1);
  const [reviewData, setReviewData] = useState([]);
  const [distance, setDistance] = useState({});
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const [sendEmail, setSendEmail] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [searchParams] = useSearchParams();

  useEffect(() => {
    document.title = appName + " | Home";
  });
  let booking = searchParams.get("booking") === "true";
  useEffect(() => {
    const timer = setTimeout(() => {
      // Call your function to send the request here
      if (booking) {
        confirmation();
      }
    }, 2000); // 5000 milliseconds = 5 seconds

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const confirmation = () => {
    const eml = searchParams.get("email");
    const name = searchParams.get("name");
    const pick_up = searchParams.get("pick_up");
    const drop_off = searchParams.get("drop_off");
    const datetime = searchParams.get("datetime");
    if (!sendEmail) {
      completed({ email: eml, name, pick_up, drop_off, datetime })
        .then((response) => {
          setSendEmail(true);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleStep1Click = () => {
    if (pickUp !== "" || dropOff !== "") {
      // if (isLoaded && !loadError) {
      const service = new window.google.maps.DistanceMatrixService();
      service.getDistanceMatrix(
        {
          origins: [pickUp],
          destinations: [dropOff],
          travelMode: "DRIVING",
        },
        (response, status) => {
          if (status === "OK") {
            const distance = response.rows[0].elements[0].distance;
            setDistance(distance);
            setStep(2);
            setError(undefined);
            return;
          } else {
            setError({
              message: "Cant find distance of location. try again. status: " + status,
            });
          }
        }
      );
      // }
    } else {
      setError({
        message: "All fields are required",
      });
    }
  };
  // const radiusCheck = (places) => {
  //   // Filter places within a specific radius
  //   const center = { lat: -31.94849821476377, lng: 115.97324305742819 };
  //   const radius = 50000;

  //   const placesWithinRadius = places.filter((place) => {
  //     const distance = window.google.maps.geometry.spherical.computeDistanceBetween(place.geometry.location, center);
  //     return distance <= radius;
  //   });
  //   return placesWithinRadius;
  // };
  const pickUpChange = (place) => {
    setPickUp(place);
  };
  const dropOffChange = (place) => {
    setDropOff(place);
  };

  const nameChange = (e) => {
    setName(e.target.value);
  };
  const phoneNumberChange = (e) => {
    setPhoneNumber(e.target.value);
    const isValid = isValidAustralianNumber(e.target.value);
    setIsPhoneNumberValid(isValid);
  };
  const emailChange = (e) => {
    setEmail(e.target.value);
    validateEmail(e.target.value) ? setError(undefined) : setError({ message: "Email not valid" });
  };
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const handleTimeChange = (date) => {
    setSelectedTime(date);
  };
  const handleStep2Click = () => {
    if (name !== "" && phoneNumber !== "" && isPhoneNumberValid && email !== "" && validateEmail(email) && selectedDate !== null && selectedTime !== null) {
      setStep(3);
      setError(undefined);
      return;
    }
    setError({
      message: "All fields are required",
    });
  };
  const flightNumberChange = (e) => {
    setFlightNumber(e.target.value);
  };
  const passengerChange = (e) => {
    e.target.value > 1 && setPassengers(e.target.value);
  };
  const passengerIncrees = () => {
    setPassengers(passengers + 1);
  };
  const passengerDecrees = () => {
    passengers > 1 && setPassengers(passengers - 1);
  };
  const handleBackClick = () => {
    console.log(step);
    if (step === 2) {
      setDropOff("");
      setPickUp("");
    }
    setStep(step - 1);
  };
  const handleReviewDetailsClick = () => {
    setBtnLoading(false);
    if (passengers > 0) {
      const data = [pickUp, dropOff, name, phoneNumber, email, selectedDate, selectedTime, flightNumber, passengers, distance];
      setStep(4);
      setReviewData(data);
      setError(undefined);
      return;
    }
    setError({
      message: "All fields are required",
    });
  };
  const handleConfirmOrderClick = () => {
    setBtnLoading(true);
    confirmBooking({
      name: name,
      email: email,
      phone_number: phoneNumber,
      pick_up: pickUp,
      drop_off: dropOff,
      passengers: passengers,
      date: selectedDate,
      time: selectedTime,
      flight_number: flightNumber,
      distance: distance,
    })
      .then((response) => {
        if (response.data.success) {
          window.location.href = response.data.data.url;
          setBtnLoading(false);
        } else {
          setError({
            message: response.data.message,
          });
        }
      })
      .catch((error) => {
        setError({
          message: error.response.data.message ?? "",
        });

        setBtnLoading(false);
      });
  };
  return (
    <React.Fragment>
      <AlertDialog title="Congratulations!" message={"Your booking has been confirmed."} open={booking} />
      <Cover />
      <Container maxWidth={containerMaxWith} sx={{ mt: 5 }}>
        <React.Fragment>
          {isMobile && (
            <React.Fragment>
              <Box>
                {step > 1 && (
                  <Btn size="small" color="primary" onClick={handleBackClick}>
                    <KeyboardBackspaceIcon /> Back
                  </Btn>
                )}
              </Box>
              {step === 4 ? (
                <Typography align="center" variant="body1" sx={{ mb: 3 }}>
                  Please review your booking details before proceeding:
                </Typography>
              ) : (
                <Typography align="center" variant="body1">
                  {step === 1 ? "Please enter your pick up & drop off addresses:" : "Please fill out the form below"}
                </Typography>
              )}
            </React.Fragment>
          )}
        </React.Fragment>
        {step === 1 && (
          <React.Fragment>
            {isMobile ? (
              <Step1Mobile pickUpChange={pickUpChange} pickUp={pickUp} dropOffChange={dropOffChange} dropOff={dropOff} handleStep1Click={handleStep1Click} error={error} />
            ) : (
              <Step1DeskTop pickUpChange={pickUpChange} pickUp={pickUp} dropOffChange={dropOffChange} dropOff={dropOff} handleStep1Click={handleStep1Click} error={error} handleBackClick={handleBackClick} step={step} />
            )}
          </React.Fragment>
        )}
        {step === 2 && (
          <React.Fragment>
            {isMobile ? (
              <Step2Mobile
                nameChange={nameChange}
                name={name}
                phoneNumberChange={phoneNumberChange}
                phoneNumber={phoneNumber}
                emailChange={emailChange}
                email={email}
                selectedDate={selectedDate}
                handleDateChange={handleDateChange}
                selectedTime={selectedTime}
                handleTimeChange={handleTimeChange}
                handleStep2Click={handleStep2Click}
                error={error}
                isPhoneNumberValid={isPhoneNumberValid}
              />
            ) : (
              <Step2DeskTop
                nameChange={nameChange}
                name={name}
                phoneNumberChange={phoneNumberChange}
                phoneNumber={phoneNumber}
                emailChange={emailChange}
                email={email}
                selectedDate={selectedDate}
                handleDateChange={handleDateChange}
                selectedTime={selectedTime}
                handleTimeChange={handleTimeChange}
                handleStep2Click={handleStep2Click}
                error={error}
                handleBackClick={handleBackClick}
                step={step}
                isPhoneNumberValid={isPhoneNumberValid}
              />
            )}
          </React.Fragment>
        )}
        {step === 3 && (
          <React.Fragment>
            {isMobile ? (
              <Step3Mobile flightNumberChange={flightNumberChange} flightNumber={flightNumber} passengerDecrees={passengerDecrees} passengerChange={passengerChange} passengers={passengers} passengerIncrees={passengerIncrees} handleReviewDetailsClick={handleReviewDetailsClick} error={error} />
            ) : (
              <Step3DeskTop
                flightNumberChange={flightNumberChange}
                flightNumber={flightNumber}
                passengerDecrees={passengerDecrees}
                passengerChange={passengerChange}
                passengers={passengers}
                passengerIncrees={passengerIncrees}
                handleReviewDetailsClick={handleReviewDetailsClick}
                error={error}
                handleBackClick={handleBackClick}
                step={step}
                distance={distance}
              />
            )}
          </React.Fragment>
        )}
        {step === 4 && (
          <React.Fragment>
            {isMobile ? (
              <Step4Mobile handleConfirmOrderClick={handleConfirmOrderClick} reviewData={reviewData} btnLoading={btnLoading} distance={distance} />
            ) : (
              <Step4DeskTop handleConfirmOrderClick={handleConfirmOrderClick} reviewData={reviewData} handleBackClick={handleBackClick} step={step} distance={distance} btnLoading={btnLoading} />
            )}
          </React.Fragment>
        )}
      </Container>
      {isMobile ? (
        <Container sx={{ mt: 5, backgroundColor: "primary.main", color: "secondary.main" }}>
          <Box sx={{ pt: 3, pb: 3, pl: 2, pr: 2 }}>
            <Box sx={{ mt: 2, mb: 3 }}>
              <img src={bottomImage} alt="Perth Airport Transfer" width="100%" style={{ borderRadius: 10 }} />
            </Box>
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
              Busselton-Margaret's Top Airport Transfer Service
            </Typography>

            <Typography variant="body1" sx={{ mt: 2 }}>
              Welcome to Busso-Margs Airport Transfers, your premier airport transfer service operating in the enchanting Busselton-Margaret River region. We specialize in providing reliable and efficient transportation to and from the Busselton-Margaret River airport, ensuring you a hassle-free
              journey.
            </Typography>

            <Typography variant="body1" sx={{ mt: 2 }}>
              Whether you're arriving in Busselton or Margaret River, our dedicated team of professional drivers and well-maintained fleet of Busselton taxis are here to cater to your needs. As a trusted Southwest airport taxi service, we take pride in delivering exceptional customer experiences,
              making your travel comfortable and enjoyable.
            </Typography>

            <Typography variant="body1" sx={{ mt: 2 }}>
              Choose Busso-Margs Airport Transfers for a stress-free airport transfer, allowing you to focus on exploring the scenic beauty of the Busselton and Margaret River areas. With our expertise and commitment to excellence, we promise to be your reliable partner in all your transportation
              needs in this breathtaking region. Sit back, relax, and let us take care of your journey!
            </Typography>
          </Box>
        </Container>
      ) : (
        <Box sx={{ mt: 10, backgroundColor: "primary.main", color: "secondary.main" }}>
          <Container>
            <Grid container spacing={2} alignItems="center" justifyContent="center">
              <Grid item xs={4}>
                <Box>
                  <img src={bottomImage} alt="Perth Airport Transfer" width="100%" style={{ borderRadius: 10 }} />
                </Box>
              </Grid>
              <Grid item xs={8}>
                <Box sx={{ ml: 5, mb: 2, p:5 }}>
                  <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                    Busselton-Margaret's Top Airport Transfer Service
                  </Typography>

                  <Typography variant="body1" sx={{ mt: 2 }}>
                    Welcome to Busso-Margs Airport Transfers, your premier airport transfer service operating in the enchanting Busselton-Margaret River region. We specialize in providing reliable and efficient transportation to and from the Busselton-Margaret River airport, ensuring you a
                    hassle-free journey.
                  </Typography>

                  <Typography variant="body1" sx={{ mt: 2 }}>
                    Whether you're arriving in Busselton or Margaret River, our dedicated team of professional drivers and well-maintained fleet of Busselton taxis are here to cater to your needs. As a trusted Southwest airport taxi service, we take pride in delivering exceptional customer
                    experiences, making your travel comfortable and enjoyable.
                  </Typography>

                  <Typography variant="body1" sx={{ mt: 2 }}>
                    Choose Busso-Margs Airport Transfers for a stress-free airport transfer, allowing you to focus on exploring the scenic beauty of the Busselton and Margaret River areas. With our expertise and commitment to excellence, we promise to be your reliable partner in all your
                    transportation needs in this breathtaking region. Sit back, relax, and let us take care of your journey!
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
      )}
      <WhatsAppBtn phoneNumber="+61474769147" />
    </React.Fragment>
  );
};

export default Home;
