import { Box, Divider, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { getFare } from "../../../services/BookingService";
import Button from "../../common/Button/Button";

const Step4Mobile = (props) => {
  const [fare, setFare] = useState(0.0);
  const { reviewData , handleConfirmOrderClick, btnLoading } = props;
  const options = { hour12: true };

  useEffect(() => {
    getTotalFare(reviewData[9].value);
  }, [reviewData]);

  const getTotalFare = (distance) => {
    getFare({ distance })
      .then((response) => {
        setFare(response.data.data.fare);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <Box sx={{ mt: 3 }}>
      <Grid item xs={12} sx={{textAlign:'center'}}>
            <Typography sx={{ mb: 1 }} variant="body2" color="textSecondary">
              Name: <strong>{reviewData[2]}</strong>
            </Typography>
            <Typography sx={{ mb: 1 }} variant="body2" color="textSecondary">
              Phone Number: <strong>04{reviewData[3]}</strong>
            </Typography>
            <Typography sx={{ mb: 1 }} variant="body2" color="textSecondary">
              Email: <strong>{reviewData[4]}</strong>
            </Typography>
            <Typography sx={{ mb: 1 }} variant="body2" color="textSecondary">
              Pick Up: <strong>{reviewData[0]}</strong>
            </Typography>
            <Typography sx={{ mb: 1 }} variant="body2" color="textSecondary">
              Drop Off: <strong>{reviewData[1]}</strong>
            </Typography>
            <Typography sx={{ mb: 1 }} variant="body2" color="textSecondary">
              No. of Passengers: <strong>{reviewData[8]}</strong>
            </Typography>
            <Typography sx={{ mb: 1 }} variant="body2" color="textSecondary">
              Date: <strong>{reviewData[5].$d.toLocaleDateString()}</strong>
            </Typography>
            <Typography sx={{ mb: 1 }} variant="body2" color="textSecondary">
              Time: <strong>{reviewData[6].$d.toLocaleTimeString(undefined, options)}</strong>
            </Typography>
            <Typography sx={{ mb: 1 }} variant="body2" color="textSecondary">
              Flight Number: <strong>{reviewData[7]}</strong>
            </Typography>
            <Typography sx={{ mb: 1 }} variant="body2" color="textSecondary">
              Distance: <strong>{reviewData[9] && reviewData[9].text}</strong>
            </Typography>
            <Divider />
            <Typography sx={{ fontWeight: "bold", mt:1 }} variant="body2" color={"GrayText"}>
              Your fare is
            </Typography>
            <Typography sx={{ fontWeight: "bold" }} variant="h5" color={"GrayText"}>
              ${fare}
            </Typography>
          </Grid>
      <Box sx={{ mt: 3 }}>
        <Button name="Confirm Booking" fullWidth={true} onClick={handleConfirmOrderClick} loading={btnLoading} />
      </Box>
    </Box>
  );
};

export default Step4Mobile;
