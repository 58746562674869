import { Box, Grid,IconButton, TextField, Typography } from "@mui/material";
import Button from "../../common/Button/Button";
import RemoveOutlinedIcon from "@mui/icons-material/RemoveOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
const Step3Mobile = (props) => {
  const { flightNumberChange, flightNumber, passengerDecrees, passengerChange, passengers, passengerIncrees,handleReviewDetailsClick, error } = props;
  return (
    <Box sx={{ mt: 3 }}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography variant="subtitle2" color="textSecondary" sx={{ mb: 1 }}>
            Flight number (optional)
          </Typography>
          <TextField size="small" fullWidth variant="outlined" onChange={flightNumberChange} value={flightNumber} />
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle2" color="textSecondary" sx={{ mb: 1 }}>
            Passengers
          </Typography>
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <IconButton size="small" sx={{ border: "solid 1px" }} onClick={passengerDecrees}>
                <RemoveOutlinedIcon />
              </IconButton>
            </Grid>
            <Grid item xs={6}>
              <TextField
                type="number"
                size="small"
                fullWidth
                variant="outlined"
                onChange={passengerChange}
                value={passengers}
                InputProps={{
                  inputProps: {
                    style: { textAlign: "center" },
                  },
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <IconButton size="small" sx={{ border: "solid 1px" }} onClick={passengerIncrees}>
                <AddOutlinedIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Typography variant="body2" color="error" sx={{ m: 1 }} align="center">
        {error && error.message}
      </Typography>
      <Box sx={{ mt: 3 }}>
        <Button name="Review Details" fullWidth={true} onClick={handleReviewDetailsClick} loading={false} />
      </Box>
    </Box>
  );
};

export default Step3Mobile;
