import { Button, Typography } from "@mui/material";
import CallIcon from "@mui/icons-material/Call";
import { Box, Container } from "@mui/system";
import coverImage from "../../../assets/img/cover-mobile.png";
import coverDesk from "../../../assets/img/cover.png";
import { useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { containerMaxWith } from "../../../utils/helpers";

const Cover = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const styles = {
    coverImage: {
      minHeight: isMobile ? "calc(100vw * 1.3)" : "100vh",
      backgroundImage: isMobile ? `url(${coverImage})` : `url(${coverDesk})`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
    },
    titleSecond: {
      color: "primary.main",
      fontWeight: "bold",
    },
  };
  const numberClick = () => {
    window.location.href = "tel:0474769147";
  };
  return (
    <React.Fragment>
      {isMobile ? (
        <Box style={styles.coverImage}>
          <Container>
            {/* <Typography variant="h4" sx={{ fontWeight: 300, position: "absolute", top: "150px", width: "265px" }}>
              We help you move <span style={styles.titleSecond}>To</span> and <span style={styles.titleSecond}>From Busso Margs Airport.</span>
              <Button size="large" color="secondary" variant="contained" startIcon={<CallIcon sx={{ backgroundColor: "primary.main", borderRadius: "50%", marginLeft: "-10px", padding: 0.7 }} color="secondary" size="large" />} sx={{ mt: 3, borderRadius: 10, startIcon: { marginLeft: "-5px" } }}>
                <Typography sx={{ fontWeight: "bold" }} variant="body1">
                  047 476 9147
                </Typography>
              </Button>
            </Typography> */}
          </Container>
        </Box>
      ) : (
        <Box>
          <img src={coverDesk} alt="" width="100%" />
          <Container maxWidth={containerMaxWith}>
            {/* <Typography variant="h2" sx={{ fontWeight: 300, position: "absolute", top: "180px", width: "465px" }}>
            We help you move <span style={styles.titleSecond}>To</span> and <span style={styles.titleSecond}>From Busso Margs Airport.</span>
              <br />
              <Button
                onClick={numberClick}
                size="large"
                color="secondary"
                variant="contained"
                startIcon={<CallIcon sx={{ backgroundColor: "primary.main", borderRadius: "50%", marginLeft: "-10px", padding: 0.7 }} color="secondary" size="large" />}
                sx={{ mt: 3, borderRadius: 10, startIcon: { marginLeft: "-5px" } }}>
                <Typography sx={{ fontWeight: "bold" }} variant="body1">
                  047 476 9147
                </Typography>
              </Button>
            </Typography> */}
          </Container>
        </Box>
      )}
    </React.Fragment>
  );
};
export default Cover;
