import { Box, InputAdornment, TextField, Typography } from "@mui/material";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import FlightOutlinedIcon from "@mui/icons-material/FlightOutlined";
import Button from "../../common/Button/Button";
import { useEffect } from "react";
import { useJsApiLoader } from "@react-google-maps/api";
import { point, buffer, bbox } from '@turf/turf';
const libraries = ["places", "geometry"];
const Step1Mobile = (props) => {
  const { pickUpChange, dropOffChange, handleStep1Click, error } = props;
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyCJ-1iwhdrENQCUunZ4qcAlXF9pSnsq8bg",
    libraries: libraries,
  });
  useEffect(() => {
    if (isLoaded) {
      // Create a bounding box with sides ~50km away from the center point
      const pickUpInput = document.getElementById("pickup-input");
      const dropOffInput = document.getElementById("dropoff-input");
      var options = {
        types: ["geocode","establishment"],
        componentRestrictions: { country: "au" },
        bounds: createBounds(),
        strictBounds: true,
      };
      var autocomplete1 = new window.google.maps.places.Autocomplete(pickUpInput, options);
      var autocomplete2 = new window.google.maps.places.Autocomplete(dropOffInput, options);
      autocomplete1.addListener("place_changed", function () {
        var place = autocomplete1.getPlace();
        if (place) {
          pickUpChange(place.formatted_address);
        } else {
          pickUpChange("");
        }
      });
      autocomplete2.addListener("place_changed", function () {
        var place = autocomplete2.getPlace();
        if (place) {
          dropOffChange(place.formatted_address);
        } else {
          dropOffChange("");
        }
      });
    }
  }, [isLoaded]);
  // Create bounds object for 50-kilometer radius from Perth Airport
  // function createBounds() {
  //   var airportLocation = new window.google.maps.LatLng(-33.68563498854492, 115.39883390911235); // Busso Airport coordinates
  //   var bounds = new window.google.maps.LatLngBounds();

  //   // Set bounds as a circle with 50-kilometer radius
  //   bounds.extend(window.google.maps.geometry.spherical.computeOffset(airportLocation, 100000, 0));
  //   bounds.extend(window.google.maps.geometry.spherical.computeOffset(airportLocation, 100000, 90));
  //   bounds.extend(window.google.maps.geometry.spherical.computeOffset(airportLocation, 100000, 180));
  //   bounds.extend(window.google.maps.geometry.spherical.computeOffset(airportLocation, 100000, 270));

  //   return bounds;
  // }
  function createBounds() {
    const airportLatitude = -33.68563498854492;
    const airportLongitude = 115.39883390911235;
    const distance = 100; // Desired distance in kilometers

    // Create a point geometry using turf.point
    const pointGeometry = point([airportLongitude, airportLatitude]);

    // Calculate the buffered area using turf.buffer
    const bufferedArea = buffer(pointGeometry, distance, { units: "kilometers" });

    // Calculate the bounding box using turf.bbox
    const bboxCoordinates = bbox(bufferedArea);

    // Create LatLngBounds object
    const bounds = new window.google.maps.LatLngBounds(new window.google.maps.LatLng(bboxCoordinates[1], bboxCoordinates[0]), new window.google.maps.LatLng(bboxCoordinates[3], bboxCoordinates[2]));
    return bounds;
  }
  return (
    <Box sx={{ mt: 3 }}>
      <Box sx={{ display: "flex", alignItems: "flex-end", width: "100%" }}>
        <TextField
          size="small"
          fullWidth
          label="Pick Up"
          variant="outlined"
          InputProps={{
            "aria-label": "Place",
            startAdornment: (
              <InputAdornment position="start">
                <LocationOnOutlinedIcon />
              </InputAdornment>
            ),
          }}
          id="pickup-input"
        />
        <FlightOutlinedIcon color="secondary" sx={{ backgroundColor: "primary.main", p: 1, ml: 0.5, borderRadius: 1 }} />
      </Box>
      <Box sx={{ display: "flex", alignItems: "flex-end", mt: 3 }}>
        <TextField
          size="small"
          fullWidth
          label="Drop Off"
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LocationOnOutlinedIcon />
              </InputAdornment>
            ),
          }}
          id="dropoff-input"
        />
        <FlightOutlinedIcon color="secondary" sx={{ backgroundColor: "primary.main", p: 1, ml: 0.5, borderRadius: 1 }} />
      </Box>
      <Typography variant="body2" color="error" sx={{ m: 1 }} align="center">
        {error && error.message}
      </Typography>
      <Box sx={{ mt: 3 }}>
        <Button name="Next" fullWidth={true} onClick={handleStep1Click} loading={false} />
      </Box>
    </Box>
  );
};

export default Step1Mobile;
