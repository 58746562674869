import { Box, Grid, IconButton, Paper, TextField, Typography } from "@mui/material";
import RemoveOutlinedIcon from "@mui/icons-material/RemoveOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import Button from "../../common/Button/Button";
import { Button as Btn } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
const Step3DeskTop = (props) => {
  const { flightNumberChange, flightNumber, passengerDecrees, passengerChange, passengers, passengerIncrees,handleReviewDetailsClick, error, handleBackClick, step } = props;
  return (
    <Paper sx={{ pt: 4, pb: 4, pl: 6, pr: 6, borderRadius: 10, mt: -15, zIndex: 0, position: "relative" }}>
      <Box>
        {step > 1 && (
          <Btn size="small" color="primary" onClick={handleBackClick}>
            <KeyboardBackspaceIcon /> Back
          </Btn>
        )}
      </Box>
      <Typography align="left" variant="body1" sx={{ mb: 3 }}>
        Please fill out the form below
      </Typography>

      <Box sx={{ mt: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Typography variant="subtitle2" color="textSecondary" sx={{ mb: 1 }}>
              Flight number (optional)
            </Typography>
            <TextField size="small" fullWidth variant="outlined" onChange={flightNumberChange} value={flightNumber} />
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle2" color="textSecondary" sx={{ mb: 1 }}>
              Passengers
            </Typography>
            <Grid container spacing={1}>
              <Grid item xs={3} sx={{ textAlign: "center" }}>
                <IconButton size="small" sx={{ border: "solid 1px" }} onClick={passengerDecrees}>
                  <RemoveOutlinedIcon />
                </IconButton>
              </Grid>
              <Grid item xs={6} sx={{ textAlign: "center" }}>
                <TextField
                  type="number"
                  size="small"
                  fullWidth
                  variant="outlined"
                  onChange={passengerChange}
                  value={passengers}
                  InputProps={{
                    inputProps: {
                      style: { textAlign: "center" },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={3} sx={{ textAlign: "center" }}>
                <IconButton size="small" sx={{ border: "solid 1px" }} onClick={passengerIncrees}>
                  <AddOutlinedIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Typography variant="body2" color="error" sx={{ m: 1 }} align="center">
          {error && error.message}
        </Typography>
        <Box sx={{ mt: 3 }}>
          <Button name="Review Details" fullWidth={true} onClick={handleReviewDetailsClick} loading={false} />
        </Box>
      </Box>
    </Paper>
  );
};

export default Step3DeskTop;
